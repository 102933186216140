import React from 'react';
import VideoCard from '../MediaList/VideoCard';
import { PbsKidsVideo } from '@/types/pbskids-graph';
import styles from './SingleVideoModule.module.scss';

interface Props {
  item: PbsKidsVideo,
  inMasthead?: boolean,
}

const SingleVideoModule: React.FC<Props> = ({
  item,
  inMasthead = false,
}) => {
  return (
    <section className={ styles.singleVideoModule }>
      <VideoCard
        videoItem={ item }
        inMasthead={ inMasthead }
        cardStyle='singleVideo'
        sizes='(min-width: 600px) 600px, 100vw'
      />
    </section>
  );
};

export default SingleVideoModule;
