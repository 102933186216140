import React from 'react';
import styles from './BlurbModule.module.scss';
import { Maybe } from '@/types/pbskids-graph';

interface Props {
  blurbCopy?: Maybe<string>
}

const BlurbModule: React.FC<Props> = ({
  blurbCopy,
}) => {
  return (blurbCopy &&
    <section
      className={ styles.blurbModule }
    >
      <h2
        className={ styles.copy }
      >{ blurbCopy }
      </h2>
    </section>
  );
};

export default BlurbModule;
